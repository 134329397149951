import request from '../utils/request'
import baseUrl from './baseUrl'

export const couponList = (data) => request({
  url: baseUrl + '/coupon/selectForBack',
  method: 'POST',
  data
})
export const addCoupon = (data) => request({
  url: baseUrl + '/coupon/add',
  method: 'POST',
  data
})
export const couponDetail = (data) => request({
  url: baseUrl + '/coupon/selectForId',
  method: 'POST',
  data
})
export const editCoupon = (data) => request({
  url: baseUrl + '/coupon/add',
  method: 'POST',
  data
})
export const changeStatus = (data) => request({
  url: baseUrl + '/coupon/modifyStatus',
  method: 'POST',
  data
})
export const couponQRCode = (data) => request({
  url: baseUrl + '/coupon/shareCoupon',
  method: 'POST',
  data
})
export const tableListAPI = (data) => request({
  url: baseUrl + '/userCoupon/selectForBack',
  method: 'POST',
  data
})
export const handle1API = (data) => request({
  url: baseUrl + '/userCoupon/modify',
  method: 'POST',
  data
})
export const handle2API = (data) => request({
  url: baseUrl + '/userCoupon/update',
  method: 'POST',
  data
})
//优惠券分类列表
export const couponCateListAPI = (data) => request({
  url: baseUrl + '/couponCate/selectForBack',
  method: 'POST',
  data
})
//优惠券添加/修改
export const couponAddAPI = (data) => request({
  url: baseUrl + '/couponCate/add',
  method: 'POST',
  data
})
//优惠券分类删除
export const couponDelAPI = (data) => request({
  url: baseUrl + '/couponCate/deleteById',
  method: 'POST',
  data
})
//优惠券分类查看全部
export const couponAllCateAPI = (data) => request({
  url: baseUrl + '/couponCate/selectAll',
  method: 'POST',
  data
})
//手机号发放
export const couponThrowPhone = (data) => request({
  url: baseUrl + '/userCoupon/sendByPhone',
  method: 'POST',
  data
})
//兑换券查询
export const getCouponCodeList = (data) => request({
  url: baseUrl + '/convert/selectForBack',
  method: 'POST',
  data
})
export const getCouponCodeListAdd = (data) => request({
  url: baseUrl + '/convert/add',
  method: 'POST',
  data
})
export const getCouponCodeListDel = (data) => request({
  url: baseUrl + '/convert/deleteById',
  method: 'POST',
  data
})
export const exprotCouponCode = (data) => request({
  url: baseUrl + '/convert/selectExport',
  method: 'POST',
  data,
  responseType:'blob'
})

//查看所有会员等级
export const userLevel = (data) => request({
  url: baseUrl + '/userLevel/selectAll',
  method: 'POST',
  data
})

//查看店长全部等级
export const distributionLevel = (data) => request({
  url: baseUrl + '/distributionLevel/selectAll',
  method: 'POST',
  data
})

//优惠卷分类分享
export const shareImg = (data) => request({
  url: baseUrl + '/couponCate/share',
  method: 'POST',
  data
})