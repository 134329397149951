<template>
  <div class="addCoupon">
    <el-breadcrumb class="top" separator="/">
      <el-breadcrumb-item>添加优惠券</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="form" label-width="80px">
      <el-form-item label="优惠券名称">
        <el-input v-model="formList.couponName" placeholder="请输入优惠券名称"></el-input>
      </el-form-item>
      <el-form-item label="所属分类">
        <el-select v-model="formList.cateId" placeholder="请选择">
          <el-option v-for="item in cateOptions" :key="item.cateId" :label="item.cateName" :value="item.cateId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="优惠券价格" class="col">
        <el-input type="number" v-model="formList.couponPrice" placeholder="请输入优惠券价格"></el-input>
      </el-form-item>
      <el-form-item label="可领取次数" class="col lingqu">
        <el-input type="number" v-model="formList.couponNum" placeholder="请输入领取次数"></el-input>
        <div style="color: red; display: inline-block; margin-left: 10px">
          (0：表示不限次数，1：表示可以领取1次，2：表示可以领取2次，以此类推)
        </div>
      </el-form-item>
      <el-form-item label="满减金额">
        <el-input type="number" v-model="formList.fullMoney" placeholder="请输入满减金额"></el-input>
        <div style="color: red; display: inline-block; margin-left: 10px">
          (0：表示不限金额，100：一次购物满100可以使用，500：一次购物满500可以使用，以此类推)
        </div>
      </el-form-item>
      <el-form-item label="领取方式">
        <el-select v-model="formList.receiveWay" placeholder="请选择领取方式">
          <el-option v-for="item in receiveWayOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="发放数量">
        <div>
          <el-radio v-model="numsType" :label="1">不限数量</el-radio>
          <el-radio v-model="numsType" :label="2">指定数量</el-radio>
        </div>
        <el-input-number v-if="numsType == 2" v-model="formList.stock" :min="1" label="发放数量"></el-input-number>
      </el-form-item>
      <div v-if="formList.receiveWay == 4">
        <el-form-item label="定时周期">
          <el-radio @change="timerTypeChange" v-model="formList.timerType" :label="1">按周</el-radio>
          <el-radio @change="timerTypeChange" v-model="formList.timerType" :label="2">按月</el-radio>
        </el-form-item>
        <el-form-item label="发放日期">
          <el-select v-model="formList.weekMonth" placeholder="请选择">
            <el-option v-for="item in computedWeek" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发放时间">
          <el-time-picker value-format="HH:mm" format="HH:mm" v-model="formList.timerTime" placeholder="领取时间">
          </el-time-picker>
        </el-form-item>
        <el-form-item label="领取天数">
          <el-select v-model="formList.receiveDay" placeholder="请选择">
            <el-option v-for="item in computedDay" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="领取时间">
          <el-time-picker value-format="HH:mm:ss" format="HH:mm:ss" v-model="formList.receiveTime" placeholder="领取时间">
          </el-time-picker>
        </el-form-item>
      </div>
      <el-form-item label="使用时间">
        <el-date-picker v-model="formList.useBeginTime" type="datetime" placeholder="选择日期时间"
          value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
        --
        <el-date-picker v-model="formList.useEndTime" type="datetime" placeholder="选择日期时间"
          value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>

        <div style="color: red; display: inline-block; margin-left: 10px">
          (表示该优惠券只有购买这个时间段的产品才可以使用)
        </div>
      </el-form-item>
      <el-form-item label="有效期类型">
        <el-radio v-model="formList.timeType" label="1">指定开始时间和结束时间</el-radio>
        <el-radio v-model="formList.timeType" label="2">指定有效天数</el-radio>
      </el-form-item>
      <el-form-item label="有效天数" v-show="formList.timeType == '2'">
        <el-input v-model="effectiveDays" placeholder="请输入有效天数"></el-input>
      </el-form-item>
      <el-form-item label="有效时间" v-show="formList.timeType == '1'">
        <el-date-picker v-model="beginTime" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
        --
        <el-date-picker v-model="endTime" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="一键领取">
        <el-radio v-model="formList.oneKey" label="1">是</el-radio>
        <el-radio v-model="formList.oneKey" label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="是否老用户回归券">
        <el-radio v-model="formList.isOld" label="1">是</el-radio>
        <el-radio v-model="formList.isOld" label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="发放人群">
        <el-select v-model="formList.userType" placeholder="请选择发放人群">
          <el-option v-for="item in userTypeS" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="formList.userType == '1'">
        <el-radio v-model="formList.userIds" label="1">注册万旅网新用户</el-radio>
        <el-radio v-model="formList.userIds" label="2">注册万小店新店长</el-radio>
      </el-form-item>
      <el-form-item v-if="formList.userType == '2'">
        <el-checkbox-group v-model="userIdss" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="i in wvSData" :key="i.cateId" v-model="userIdss" :label="i.cateId">{{ i.cateName
          }}</el-checkbox>
        </el-checkbox-group>

      </el-form-item>
      <el-form-item v-if="formList.userType == '3'">
        <el-checkbox-group v-model="userIdss2" @change="changeWl2">
          <el-checkbox v-for="i in dzSData" :key="i.cateId" :label="i.cateId">{{ i.cateName }}</el-checkbox>
        </el-checkbox-group>

      </el-form-item>
      <el-form-item label="使用说明" prop="remark">
        <VueUeditorWrap v-model="formList.remark" :config="myConfig" ref="myTextEditor"></VueUeditorWrap>
      </el-form-item>
      <el-form-item label="使用范围">
        <el-radio v-model="couponScopeValue" label="0">通用</el-radio>
        <el-radio v-model="couponScopeValue" label="1">指定商品</el-radio>
      </el-form-item>
      <el-form-item label="选择产品" v-show="couponScopeValue == '1'">
        <el-button type="primary" @click="roomDialogVisible = true">选择客房</el-button>
        <el-button type="primary" @click="ticketDialogVisible = true">选择门票</el-button>
        <el-button type="primary" @click="lineDialogVisible = true">选择线路</el-button>
        <el-button type="primary" @click="seckDialogVisible = true">选择秒杀</el-button>
        <el-button type="primary" @click="groupDialogVisible = true">选择拼团</el-button>
        <el-button type="primary" @click="foodDialogVisible = true">选择美食</el-button>
        <el-button type="primary" @click="presellDialogVisible = true">选择预售</el-button>
        <el-button type="primary" @click="giftDialogVisible = true">选择超级礼包</el-button>
      </el-form-item>
      <el-form-item label="选择的产品" v-show="couponScopeValue == '1'">
        <el-form-item label="客房名称:" v-for="item in roomListData" :key="item.roomId" v-show="item.isShow">{{ item.roomName
        }}
          <a href="javascript:;" style="color: red; margin-left: 10px" @click="onDelRoomId(item.roomId, item)">删除</a>
        </el-form-item>
        <!-- <el-form-item v-if="roomListData.length > 6">
          <div style="margin-left: 60px">展开全部</div>
        </el-form-item> -->
        <el-form-item label="门票名称:" v-for="item in ticketListData" :key="item.ticketId" v-show="item.isShow">
          {{ item.ticketName }}
          <a href="javascript:;" style="color: red; margin-left: 10px" @click="onDelticketId(item.ticketId, item)">删除</a>
        </el-form-item>
        <!-- <el-form-item v-if="ticketListData.length > 6">
          <div style="margin-left: 60px">展开全部</div>
        </el-form-item> -->
        <el-form-item label="线路名称:" v-for="item in travelListData" :key="item.travelId" v-show="item.isShow">{{
          item.travelName }}
          <a href="javascript:;" style="color: red; margin-left: 10px" @click="onDeltravelId(item.travelId, item)">删除</a>
        </el-form-item>
        <el-form-item label="秒杀名称:" v-for="item in seckListData" :key="item.seckillId" v-show="item.isShow">{{
          item.seckillName }}
          <a href="javascript:;" style="color: red; margin-left: 10px" @click="onDelseckId(item.seckillId, item)">删除</a>
        </el-form-item>
        <el-form-item label="拼团名称:" v-for="item in groupListData" :key="item.groupBuyId" v-show="item.isShow">{{
          item.groupBuyName }}
          <a href="javascript:;" style="color: red; margin-left: 10px" @click="onDelgroupId(item.groupBuyId, item)">删除</a>
        </el-form-item>
        <el-form-item label="美食名称:" v-for="item in foodListData" :key="item.foodId">{{ item.foodName }}
          <a href="javascript:;" style="color: red; margin-left: 10px" @click="onDelfood(item.foodId, item)">删除</a>
        </el-form-item>
        <el-form-item label="预售名称:" v-for="item in presellListData" :key="item.presaleId" v-show="item.isShow">{{
          item.presaleName }}
          <a href="javascript:;" style="color: red; margin-left: 10px"
            @click="onDelpresellId(item.presaleId, item)">删除</a>
        </el-form-item>
        <el-form-item label="礼包名称:" v-for="item in giftListData" :key="item.packId">{{ item.title }}
          <a href="javascript:;" style="color: red; margin-left: 10px" @click="onDelGiftId(item.packId)">删除</a>
        </el-form-item>
        <!-- <el-form-item v-if="travelListData.length > 6">
            <div style="margin-left: 60px">展开全部</div>
          </el-form-item> -->
      </el-form-item>
    </el-form>
    <div class="btn">
      <el-button type="primary" @click="onSave">保存</el-button>
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog title="选择客房" class="addHotel" :visible.sync="roomDialogVisible" width="55%">
      <div class="search">
        <span>客房名称:</span>
        <el-input v-model="roomList.roomName" placeholder="请输入房型名称"></el-input>
        <span>所属酒店:</span>
        <el-select clearable @clear="onClear(2)" v-model="roomList.hotelId" placeholder="请选择所属酒店">
          <el-option v-for="item in hotelOptions" :key="item.cateId" :label="item.cateName" :value="item.cateId">
          </el-option>
        </el-select>
        <br />
        <br />
        <span>状态:</span>
        <el-select clearable v-model="roomList.status" placeholder="请选择" @clear="onClear(1)">
          <el-option v-for="item in ticketStatusOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-button type="primary" @click="getRoomList()">查询</el-button>
      </div>
      <el-table @selection-change="onSelectChange" border :data="roomTableData" style="width: 100%">
        <el-table-column align="center" type="selection"></el-table-column>
        <el-table-column align="center" prop="roomName" label="客房名称"></el-table-column>
        <el-table-column align="center" prop="hotelName" label="所属酒店"></el-table-column>
        <el-table-column align="center" prop="strStatus" label="上下架">
        </el-table-column>
        <el-table-column align="center" prop="isShow" label="是否显示">
        </el-table-column>
        <el-table-column align="center" prop="wlShow" label="万旅网显示">
        </el-table-column>

        <el-table-column align="center" label="操作">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" @click="onAddRoomRow(row.roomId)" :disabled="roomIds.indexOf(Number(row.roomId)) !== -1 ? true : false
              ">添加</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="roomList.currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="roomList.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="roomPagination.total">
      </el-pagination>
      <el-button class="add" type="primary" @click="onAddRoom">添加选中项</el-button>
      <span slot="footer" class="dialog-footer">
        <el-button @click="roomDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="roomDialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择门票" class="addHotel" :visible.sync="ticketDialogVisible" width="55%">
      <div class="search">
        <span>门票名称:</span>
        <el-input v-model="ticketList.ticketName" placeholder="请输入门票名称"></el-input>
        <span>所属门票:</span>
        <el-select clearable @clear="onClear(3)" v-model="ticketList.sceneryId" placeholder="请选择所属景区">
          <el-option v-for="item in ticketOptions" :key="item.cateId" :label="item.cateName" :value="item.cateId">
          </el-option>
        </el-select>
        <br />
        <div style="margin-top: 10px"></div>
        <span>状态:</span>
        <el-select clearable v-model="ticketList.status" placeholder="请选择" @clear="onClear(4)">
          <el-option v-for="item in ticketStatusOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-button type="primary" @click="getTicketList()">查询</el-button>
      </div>
      <el-table @selection-change="onSelectChange1" border :data="ticketTableData" style="width: 100%">
        <el-table-column align="center" type="selection"></el-table-column>
        <el-table-column align="center" prop="ticketName" label="门票名称"></el-table-column>
        <el-table-column align="center" prop="sceneryName" label="所属景区"></el-table-column>
        <el-table-column align="center" prop="strStatus" label="上下架">
        </el-table-column>
        <el-table-column align="center" prop="isShow" label="是否显示">
        </el-table-column>
        <el-table-column align="center" prop="wlShow" label="万旅网显示">
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" @click="onAddTicketRow(row.ticketId)"
              :disabled="ticketIds.indexOf(row.ticketId) !== -1 ? true : false">添加</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
        :current-page="ticketList.currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="ticketList.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="ticketPagination.total">
      </el-pagination>
      <el-button class="add" type="primary" @click="onAddTicket">添加选中项</el-button>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ticketDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="ticketDialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择线路" class="addHotel" :visible.sync="lineDialogVisible" width="55%">
      <div class="search">
        <span>线路名称:</span>
        <el-input v-model="travelList.travelName" placeholder="请输入线路名称"></el-input>

        <span>状态:</span>
        <el-select clearable v-model="travelList.status" placeholder="请选择" @clear="onClear(5)">
          <el-option v-for="item in ticketStatusOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-button type="primary" @click="getLineList">查询</el-button>
      </div>
      <el-table @selection-change="onSelectChange2" border :data="travelTableData" style="width: 100%">
        <el-table-column align="center" type="selection"></el-table-column>
        <el-table-column align="center" prop="travelName" label="线路名称"></el-table-column>
        <el-table-column align="center" prop="cateName" label="所属分类"></el-table-column>
        <el-table-column align="center" prop="strStatus" label="上下架">
        </el-table-column>
        <el-table-column align="center" prop="isShow" label="是否显示">
        </el-table-column>
        <el-table-column align="center" prop="wlShow" label="万旅网显示">
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" @click="onAddtravelRow(row.travelId)"
              :disabled="travelIds.indexOf(row.travelId) !== -1 ? true : false">添加</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
        :current-page="travelList.currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="travelList.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="travelPagination.total">
      </el-pagination>
      <el-button class="add" type="primary" @click="onAddtravel">添加选中项</el-button>
      <span slot="footer" class="dialog-footer">
        <el-button @click="lineDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="lineDialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择秒杀" class="addHotel" :visible.sync="seckDialogVisible" width="55%">
      <div class="search">
        <span>秒杀名称:</span>
        <el-input v-model="seckList.seckillName" placeholder="请输入秒杀名称"></el-input>

        <span>状态:</span>
        <el-select clearable v-model="seckList.status" placeholder="请选择" @clear="onClear(6)">
          <el-option v-for="item in ticketStatusOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-button type="primary" @click="getSeckList()">查询</el-button>
      </div>
      <el-table @selection-change="onSelectChange3" border :data="seckTableData" style="width: 100%">
        <el-table-column align="center" type="selection"></el-table-column>
        <el-table-column align="center" prop="seckillName" label="秒杀名称"></el-table-column>
        <el-table-column align="center" prop="cateName" label="所属分类"></el-table-column>
        <el-table-column align="center" prop="strStatus" label="上下架">
        </el-table-column>
        <el-table-column align="center" prop="isShow" label="是否显示">
        </el-table-column>
        <el-table-column align="center" prop="wlShow" label="万旅网显示">
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" @click="onAddseckRow(row.seckillId)"
              :disabled="seckIds.indexOf(row.seckillId) !== -1 ? true : false">添加</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange3" @current-change="handleCurrentChange3"
        :current-page="seckList.currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="seckList.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="secktotal">
      </el-pagination>
      <el-button class="add" type="primary" @click="onAddSeck">添加选中项</el-button>
      <span slot="footer" class="dialog-footer">
        <el-button @click="seckDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="seckDialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择拼团" class="addHotel" :visible.sync="groupDialogVisible" width="55%">
      <div class="search">
        <span>拼团名称:</span>
        <el-input v-model="groupList.groupBuyName" placeholder="请输入拼团名称"></el-input>
        <span>状态:</span>
        <el-select clearable v-model="groupList.status" placeholder="请选择" @clear="onClear(7)">
          <el-option v-for="item in ticketStatusOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-button type="primary" @click="getGroupList()">查询</el-button>
      </div>
      <el-table @selection-change="onSelectChange4" border :data="groupTableData" style="width: 100%">
        <el-table-column align="center" type="selection"></el-table-column>
        <el-table-column align="center" prop="groupBuyName" label="拼团名称"></el-table-column>
        <el-table-column align="center" prop="cateName" label="所属分类"></el-table-column>
        <el-table-column align="center" prop="strStatus" label="上下架">
        </el-table-column>
        <el-table-column align="center" prop="isShow" label="是否显示">
        </el-table-column>
        <el-table-column align="center" prop="wlShow" label="万旅网显示">
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" @click="onAddgroupRow(row.groupBuyId)"
              :disabled="groupIds.indexOf(row.groupBuyId) !== -1 ? true : false">添加</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange4" @current-change="handleCurrentChange4"
        :current-page="groupList.currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="groupList.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="grouptotal">
      </el-pagination>
      <el-button class="add" type="primary" @click="onAddGroup">添加选中项</el-button>
      <span slot="footer" class="dialog-footer">
        <el-button @click="groupDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="groupDialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择美食" :visible.sync="foodDialogVisible" width="70%">
      <foodList ref="foodRef" @change="foodChange" :status="2" @submit="submit"></foodList>
      <br />
      <el-button class="add" type="primary" @click="onAddFood">添加选中项</el-button>
      <span slot="footer" class="dialog-footer">
        <el-button @click="foodDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="foodDialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择预售" class="addHotel" :visible.sync="presellDialogVisible" width="55%">
      <div class="search">
        <span>预售名称:</span>
        <el-input v-model="presellList.presaleName" placeholder="请输入预售名称"></el-input>
        <span>状态:</span>
        <el-select clearable v-model="presellList.status" placeholder="请选择" @clear="onClear(8)">
          <el-option v-for="item in ticketStatusOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-button type="primary" @click="getPresellList()">查询</el-button>
      </div>
      <el-table @selection-change="onSelectChange5" border :data="presellTableData" style="width: 100%">
        <el-table-column align="center" type="selection"></el-table-column>
        <el-table-column align="center" prop="presaleName" label="预售名称"></el-table-column>
        <el-table-column align="center" prop="cateName" label="所属分类"></el-table-column>
        <el-table-column align="center" prop="strStatus" label="上下架">
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" @click="onAddpresellRow(row.presaleId)" :disabled="presellIds.indexOf(row.presaleId) !== -1 ? true : false
              ">添加</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange5" @current-change="handleCurrentChange5"
        :current-page="presellList.currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="presellList.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="preselltotal">
      </el-pagination>
      <el-button class="add" type="primary" @click="onAddPresell">添加选中项</el-button>
      <span slot="footer" class="dialog-footer">
        <el-button @click="presellDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="presellDialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择礼包" class="addHotel" :visible.sync="giftDialogVisible" width="55%">
      <giftBagList ref="giftBagRef" type="select" @change="giftBagChange" @submit="submitGift"></giftBagList>
      <el-button class="add" type="primary" @click="onAddGiftBag">添加选中项</el-button>
      <span slot="footer" class="dialog-footer">
        <el-button @click="giftDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="giftDialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import foodList from "../../food/foodList.vue";
import { allHotel } from "../../../api/hotelList";
import { roomList, roomDetails } from "../../../api/room";
import { ticketList, ticketDetails } from "../../../api/ticket";
import { addScenery, allScenery } from "../../../api/sceneryList";
import { groupBuyList, groupBuyDetail } from "../../../api/groupBuy";
import VueUeditorWrap from "vue-ueditor-wrap";
import {
  seckillInfoList,
  seckillInfoDetail,
  getPresaleList,
  getPresaleListDetails,
} from "../../../api/seckillInfo";

import { lineList, lineDetails } from "../../../api/lineList";
import { addCoupon, couponDetail, couponAllCateAPI, userLevel, distributionLevel } from "../../../api/coupon";
import giftBagList from "../seckillInfo/giftBagList.vue";
export default {
  name: "addCoupon",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  components: {
    VueUeditorWrap,
    foodList,
    giftBagList,
  },
  computed: {
    computedWeek() {
      let nums = this.formList.timerType == 1 ? 7 : 28;
      let arr = [];
      let emuParams = {
        1: "周一",
        2: "周二",
        3: "周三",
        4: "周四",
        5: "周五",
        6: "周六",
        7: "周日",
      };
      for (let i = 0; i < nums; i++) {
        let dayCurrent = i + 1;
        let obj = {
          label:
            this.formList.timerType == 1
              ? emuParams[dayCurrent]
              : dayCurrent + "号",
          value: dayCurrent,
        };
        arr.push(obj);
      }
      return arr;
    },
    computedDay() {
      let nums = this.formList.timerType == 1 ? 7 : 28;
      let arr = [];

      for (let i = 0; i < nums; i++) {
        let obj = {
          label: i + "天",

          value: i,
        };
        arr.push(obj);
      }
      return arr;
    },
  },
  data() {
    return {
      foodDialogVisible: false,
      presellDialogVisible: false,
      giftDialogVisible: false,
      myConfig: {
        toolbars: [
          [
            "simpleupload", //单图上传
            "fullscreen",
            "source",
            "|",
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "underline",
            "fontborder",
            "strikethrough",
            "superscript",
            "subscript",
            "removeformat",
            "formatmatch",
            "autotypeset",
            "blockquote",
            "pasteplain",
            "|",
            "forecolor",
            "backcolor",
            "insertorderedlist",
            "insertunorderedlist",
            "selectall",
            "|",
            "rowspacingtop",
            "rowspacingbottom",
            "lineheight",
            "|",
            "customstyle",
            "paragraph",
            "fontfamily",
            "fontsize",
            "|",
            "directionalityltr",
            "directionalityrtl",
            "indent",
            "|",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "justifyjustify",
            "|",
            "touppercase",
            "tolowercase",
            "|",
            "imagenone",
            "imageleft",
            "imageright",
            "imagecenter",
            "|",
            "emotion",
            "map",
            "insertcode",
            "background",
            "|",
            "horizontal",
            "date",
            "time",
            "spechars",
            "|",
            "inserttable",
            "deletetable",
            "insertparagraphbeforetable",
            "insertrow",
            "deleterow",
            "insertcol",
            "deletecol",
            "mergecells",
            "mergeright",
            "mergedown",
            "|",
            "print",
            "preview",
          ],
        ],
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: 600, // 初始容器高度
        initialFrameWidth: "50%", // 初始容器宽度
        serverUrl: "/config",
        UEDITOR_HOME_URL: "/static/UEditor/",
        maximumWords: "900",
      },
      cateOptions: [],
      roomDialogVisible: false,
      ticketDialogVisible: false,
      lineDialogVisible: false,
      seckDialogVisible: false,
      groupDialogVisible: false,
      roomName: "",
      secktotal: 0,
      grouptotal: 0,
      hotelOptions: [],
      roomListData: [],
      roomListIds: [],
      roomList: {
        hotelId: null,
        currentPage: 1,
        pageSize: 5,
        roomName: "",
        status: null,
      },

      roomPagination: {},
      roomTableData: [],
      hotelSelectId: null,
      ticketListData: [],
      ticketList: {
        currentPage: 1,
        pageSize: 5,
        ticketName: "",
        sceneryId: null,
        status: null,
      },
      ticketStatusOptions: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "上架",
        },
        {
          value: 2,
          label: "下架",
        },
      ],
      ticketListIds: [],
      ticketOptions: [],
      ticketTableData: [],
      ticketPagination: {},
      travelListData: [],
      travelList: {
        currentPage: 1,
        pageSize: 5,
        travelName: "",
        status: null,
      },
      travelTableData: [],
      travelListIds: [],
      travelPagination: {},

      seckListIds: [],
      seckTableData: [],
      seckListData: [],
      seckIds: [],
      seckList: {
        currentPage: 1,
        pageSize: 5,
        seckillName: "",
        status: null,
      },
      groupTableData: [],
      groupListIds: [],
      grouptotal: 0,
      groupIds: [],
      groupListData: [],
      groupList: {
        currentPage: 1,
        pageSize: 5,
        groupBuyName: "",
        status: null,
      },
      presellList: {
        currentPage: 1,
        pageSize: 5,
        presaleName: "",
        status: null,
      },
      presellTableData: [],
      preselltotal: 0,
      presellListIds: [],
      presellListData: [],
      presellIds: [],
      foodListData: [],
      foodChangeData: [],
      giftListData: [],
      giftListChangeData: [],
      numsType: 1,
      receiveWayOptions: [
        {
          value: 1,
          label: "手动领取",
        },
        {
          value: 2,
          label: "注册即送",
        },
        {
          value: 3,
          label: "扫码领券",
        },
        {
          value: 4,
          label: "定时发放",
        },
      ],
      userTypeS: [
        {
          value: 0,
          label: "全部用户",
        },
        {
          value: 1,
          label: "新用户",
        },
        {
          value: 2,
          label: "万旅网会员",
        },
        {
          value: 3,
          label: "万小店店长等级",
        },
      ],
      oneKey: "0",
      couponScopeValue: "0",
      formList: {
        couponName: "",
        couponPrice: null,
        couponNum: null,
        fullMoney: null,
        receiveWay: null,
        useBeginTime: "",
        useEndTime: "",
        timeType: "1",
        oneKey: "0",
        cateId: "",
        isOld: "0",
        remark: "",
        stock: 1,

        timerType: 1,
        weekMonth: "",
        timerTime: "",
        receiveDay: "",
        receiveTime: "",

        userType: '',
        userIds: '1'
      },
      couponScope: 0,
      effectiveDays: "",
      beginTime: "",
      endTime: "",
      roomIds: [],
      ticketIds: [],
      travelIds: [],
      allRoom: [],
      allTicket: [],
      allLine: [],
      wvSData: [],
      dzSData: [],
      userIdss: [],
      userIdss2: [],
    };
  },
  created() {
    this.getAllHotel();
    this.getRoomList();
    this.getTicketList();
    this.getAllscenery();
    this.getLineList();
    this.getSeckList();
    this.getGroupList();
    this.getCate();
    this.getPresellList();
    this.getUserLevel()
    this.getDistributionLevel()
    if (Number(this.id) !== 0) {
      this.getcouponDetail();
    }
  },

  watch: {
    couponScopeValue: function () {
      this.couponScope = Number(this.couponScopeValue);
    },
  },
  methods: {
    handleCheckedCitiesChange(value) {
      console.log(value, 33324);
      this.userIdss = value
      this.userIdss = [...new Set(this.userIdss)];
      this.formList.userIds = this.userIdss.join(",")
      console.log(this.formList.userIds, 666);
    },
    changeWl2(id) {
      this.userIdss2 = id
      this.userIdss2 = [...new Set(this.userIdss2)];
      this.formList.userIds = this.userIdss2.join(",")
      console.log(this.formList.userIds, 666, id);
    },
    async getUserLevel() {
      const { data } = await userLevel();
      this.wvSData = data.data;
    },
    async getDistributionLevel() {
      const { data } = await distributionLevel();
      this.dzSData = data.data;
      console.log(data, "dat000000000000000a");
    },
    timerTypeChange() {
      this.formList.weekMonth = "";
    },
    onDelGiftId(id) {
      let index = this.giftListData.findIndex((item) => item.packId == id);
      if (index != -1) {
        this.giftListData.splice(index, 1);
      }
    },
    onAddGiftBag() {
      if (this.giftListChangeData.length == 0) {
        return this.$message({
          showClose: true,
          message: "请选择礼包",
          type: "error",
        });
      }
      this.giftListData = Array.from(
        new Set([...this.giftListData, ...this.giftListChangeData])
      );

      this.giftDialogVisible = false;
      this.giftListChangeData = [];
    },
    giftBagChange(data) {
      console.log(data, "data");
      this.giftListChangeData = data;
      console.log(this.giftListData, "this.giftListData");
      // this.giftDialogVisible = false;
      // this.formList.giftBagId = data.giftBagId;
      // this.formList.giftBagName = data.giftBagName;
    },
    submitGift(data) {
      // this.$message.success("添加成功");
      this.onAddGiftBag();
    },
    async getCate() {
      const { data } = await couponAllCateAPI();
      console.log(data, "data");
      this.cateOptions = data.data;
    },
    onDelRoomId(id, item) {
      item.isShow = false;
      console.log(id);
      this.roomIds = this.roomIds.filter((item) => {
        return item != id;
      });
      // this.getroomDetails();
    },
    onDelticketId(id, item) {
      item.isShow = false;

      this.ticketIds = this.ticketIds.filter((item) => {
        return item != id;
      });
      // this.getticketDetails();
    },
    onDeltravelId(id, item) {
      item.isShow = false;

      this.travelIds = this.travelIds.filter((item) => {
        return item != id;
      });
      // this.getlineDetails();
    },
    onDelseckId(id, item) {
      item.isShow = false;

      this.seckIds = this.seckIds.filter((item) => {
        return item != id;
      });
      // this.getlineDetails();
    },
    onDelgroupId(id, item) {
      item.isShow = false;

      this.groupIds = this.groupIds.filter((item) => {
        return item != id;
      });
      // this.getlineDetails();
    },
    onDelpresellId(id, item) {
      item.isShow = false;

      this.presellIds = this.presellIds.filter((item) => {
        return item != id;
      });
    },
    onDelfood(id) {
      let index = this.foodListData.findIndex((item) => item.foodId == id);
      if (index != -1) {
        this.foodListData.splice(index, 1);
      }
    },
    async getcouponDetail() {
      const { data } = await couponDetail({
        couponId: Number(this.id),
      });
      console.log(data, "data");
      this.formList.couponName = data.data[0].couponName;
      this.formList.couponPrice = data.data[0].couponPrice;
      this.formList.fullMoney = data.data[0].fullMoney;
      this.formList.couponNum = data.data[0].couponNum;
      this.formList.receiveWay = data.data[0].receiveWay;
      this.formList.useBeginTime = data.data[0].useBeginTime;
      this.formList.useEndTime = data.data[0].useEndTime;
      this.formList.oneKey = data.data[0].oneKey + "";
      this.formList.timeType = data.data[0].timeType + "";
      this.formList.cateId = data.data[0].cateId;
      this.formList.isOld = data.data[0].isOld + "";
      this.formList.remark = data.data[0].remark;
      this.formList.timerType = data.data[0].timerType;
      this.formList.weekMonth = data.data[0].weekMonth;
      this.formList.timerTime = data.data[0].timerTime;
      this.formList.receiveDay = data.data[0].receiveDay;
      this.formList.receiveTime = data.data[0].receiveTime;
      this.formList.stock = data.data[0].stock;
      this.numsType = data.data[0].stock == -100 ? 1 : 2
      this.beginTime = data.data[0].beginTime;
      this.endTime = data.data[0].endTime;
      this.effectiveDays = data.data[0].effectiveDays;
      this.couponScopeValue = data.data[0].couponScope + "";

      this.formList.userType = data.data[0].userType;
      if (this.formList.userType == 2) {
        this.userIdss = data.data[0].userIds.split(',').map(Number);
      } else if (this.formList.userType == 3) {
        this.userIdss2 = data.data[0].userIds.split(',').map(Number);
      } else if (this.formList.userType == 1) {
        this.formList.userIds = data.data[0].userIds;
      }


      if (data.data[0].roomIds) {
        this.roomIds = data.data[0].roomIds.split(",").map((i) => {
          return Number(i);
        });
        this.getroomDetails();
      }
      if (data.data[0].ticketIds) {
        this.ticketIds = data.data[0].ticketIds.split(",").map((i) => {
          return Number(i);
        });
        this.getticketDetails();
      }
      if (data.data[0].travelIds) {
        this.travelIds = data.data[0].travelIds.split(",").map((i) => {
          return Number(i);
        });
        this.getlineDetails();
      }
      if (data.data[0].seckillIds) {
        this.seckIds = data.data[0].seckillIds.split(",").map((i) => {
          return Number(i);
        });
        this.getSeckDetails();
      }
      if (data.data[0].groupBuyIds) {
        this.groupIds = data.data[0].groupBuyIds.split(",").map((i) => {
          return Number(i);
        });
        this.getGroupDetails();
      }
      if (data.data[0].presaleIds) {
        this.presellIds = data.data[0].presaleIds.split(",").map((i) => {
          return Number(i);
        });
        this.getPresellDetails();
      }
      if (data.data[0].foodIds) {
        this.foodListData = data.data[0].foodModel.map((item) => {
          return {
            foodId: item.cateId,
            foodName: item.cateName,
          };
        });
      }
      if (data.data[0].packIds) {
        this.giftListData = data.data[0].packModel.map((item) => {
          return {
            packId: item.cateId,
            title: item.cateName,
          };
        });
      }
    },
    async onSave() {
      let params = JSON.parse(JSON.stringify(this.formList));
      if (params.receiveWay != 4) {
        delete params.timerType;
        delete params.weekMonth;
        delete params.timerTime;
        delete params.receiveDay;
        delete params.receiveTime;
      }
      for (const key in params) {
        if (params[key] === null || params[key] === "") {
          return this.$message({
            showClose: true,
            message: "请把数据填写完整",
            type: "error",
          });
        }
      }
      if (
        this.couponScope == 1 &&
        this.roomIds == "" &&
        this.ticketIds == "" &&
        this.seckIds == "" &&
        this.groupIds == "" &&
        this.travelIds == "" &&
        this.foodListData.length == 0 &&
        this.presellIds == "" &&
        this.giftListData.length == 0
      ) {
        return this.$message({
          showClose: true,
          message: "在指定商品,请务必选择商品",
          type: "error",
        });
      }
      if (
        this.formList.timeType == "1" &&
        (this.beginTime == "" || this.endTime == "")
      ) {
        return this.$message({
          showClose: true,
          message: "在指定开时间和结束时间,请务必选择开始和结束时间",
          type: "error",
        });
      } else if (this.formList.timeType == "2" && this.effectiveDays == "") {
        return this.$message({
          showClose: true,
          message: "在指定有效天数,请务必选择有效天数",
          type: "error",
        });
      } else if (this.formList.userType == "2") {
        if (this.userIdss.length == 0) {
          return this.$message({
            showClose: true,
            message: "把万旅网会员勾选中",
            type: "error",
          });
        }
      }else if (this.formList.userType == "3") {
        if (this.userIdss2.length == 0) {
          return this.$message({
            showClose: true,
            message: "万小店店长等级勾选中",
            type: "error",
          });
        }
      }
      const { data } = await addCoupon({
        ...params,
        couponId: Number(this.id),
        effectiveDays: this.effectiveDays,
        beginTime: this.beginTime,
        endTime: this.endTime,
        roomIds: this.roomIds.join(","),
        ticketIds: this.ticketIds.join(","),
        travelIds: this.travelIds.join(","),
        seckillIds: this.seckIds.join(","),
        groupBuyIds: this.groupIds.join(","),
        presaleIds: this.presellIds.join(","),
        foodIds: this.foodListData.map((item) => item.foodId).join(","),
        couponScope: this.couponScope,
        packIds: this.giftListData.map((item) => item.packId).join(","),
        stock: this.numsType == 1 ? -100 : params.stock
      });
      if (data.code == 0) {
        this.$message({
          showClose: true,
          message: data.msg,
          type: "success",
        });
        this.$router.go(-1);
      } else {
        this.$message({
          showClose: true,
          message: data.msg,
          type: "error",
        });
      }
    },
    async getroomDetails() {
      var arr = [];
      this.roomIds.forEach(async (item) => {
        const { data } = await roomDetails({
          roomId: Number(item),
        });
        data.data[0].isShow = true;
        arr.push(data.data[0]);
      });

      this.roomListData = arr;
    },
    async getticketDetails() {
      var arr = [];
      this.ticketIds.forEach(async (item) => {
        const { data } = await ticketDetails({
          ticketId: Number(item),
        });
        data.data[0].isShow = true;
        arr.push(data.data[0]);
      });
      this.ticketListData = arr;
    },
    async getlineDetails() {
      var arr = [];
      this.travelIds.forEach(async (item) => {
        const { data } = await lineDetails({
          travelId: Number(item),
        });
        data.data[0].isShow = true;

        arr.push(data.data[0]);
      });
      this.travelListData = arr;
      console.log(this.ticketListData, "1qweqweqw");
    },
    async getSeckDetails() {
      var arr = [];
      this.seckIds.forEach(async (item) => {
        const { data } = await seckillInfoDetail({
          seckillId: Number(item),
        });
        data.data[0].isShow = true;

        arr.push(data.data[0]);
      });
      this.seckListData = arr;
      console.log(this.seckListData, "seckListData");
    },
    async getGroupDetails() {
      var arr = [];
      this.groupIds.forEach(async (item) => {
        const { data } = await groupBuyDetail({
          groupBuyId: Number(item),
        });
        data.data[0].isShow = true;

        arr.push(data.data[0]);
      });
      this.groupListData = arr;
      console.log(this.groupListData, "groupListData");
    },
    async getPresellDetails() {
      var arr = [];
      this.presellIds.forEach(async (item) => {
        const { data } = await getPresaleListDetails({
          presaleId: Number(item),
        });
        data.data[0].isShow = true;

        arr.push(data.data[0]);
      });
      this.presellListData = arr;
      console.log(this.presellListData, "presellListData");
    },
    async getAllHotel() {
      const { data } = await allHotel();
      this.hotelOptions = data.data;
    },
    async getRoomList() {
      const { data } = await roomList(this.roomList);
      this.roomTableData = data.list;
      this.roomPagination = data.pagination;
    },
    async getAllscenery() {
      const { data } = await allScenery();
      this.ticketOptions = data.data;
    },
    async getTicketList() {
      const { data } = await ticketList(this.ticketList);
      this.ticketTableData = data.list;
      this.ticketPagination = data.pagination;
    },
    async getLineList() {
      const { data } = await lineList(this.travelList);
      console.log(data, "12121");
      this.travelTableData = data.list;
      this.travelPagination = data.pagination;
    },
    async getSeckList() {
      const { data } = await seckillInfoList(this.seckList);
      console.log(data, "12121");
      this.seckTableData = data.list;
      this.secktotal = data.pagination.total;
    },
    async getGroupList() {
      const { data } = await groupBuyList(this.groupList);
      console.log(data, "12121");
      this.groupTableData = data.list;
      this.grouptotal = data.pagination.total;
    },
    async getPresellList() {
      const { data } = await getPresaleList(this.presellList);
      console.log(data, "12121");
      this.presellTableData = data.list;
      this.preselltotal = data.pagination.total;
    },
    onAddtravelRow(id) {
      if (this.travelIds.indexOf(id) !== -1) {
        return this.$message({
          showClose: true,
          message: "数据已添加",
          type: "warning",
        });
      }
      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
      this.travelIds.push(id);
      this.getlineDetails();
    },
    onAddtravel() {
      if (this.travelListIds == "") {
        return this.$message({
          showClose: true,
          message: "请选择要添加数据",
          type: "error",
        });
      }
      this.travelIds.push(...this.travelListIds);
      this.travelIds = Array.from(new Set(this.travelIds));
      this.getlineDetails();
      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
    },
    onAddseckRow(id) {
      if (this.seckIds.indexOf(id) !== -1) {
        return this.$message({
          showClose: true,
          message: "数据已添加",
          type: "warning",
        });
      }
      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
      this.seckIds.push(id);
      this.getSeckDetails();
    },
    onAddgroupRow(id) {
      if (this.groupIds.indexOf(id) !== -1) {
        return this.$message({
          showClose: true,
          message: "数据已添加",
          type: "warning",
        });
      }
      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
      this.groupIds.push(id);
      this.getGroupDetails();
    },
    onAddpresellRow(id) {
      if (this.presellIds.indexOf(id) !== -1) {
        return this.$message({
          showClose: true,
          message: "数据已添加",
          type: "warning",
        });
      }
      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
      this.presellIds.push(id);
      this.getPresellDetails();
    },
    submit(row) {
      let map = new Map();
      let obj = {
        foodName: row.title,
        foodId: row.foodId,
      };
      this.foodListData = [obj, ...this.foodListData].filter(
        (item) => !map.has(item.foodId) && map.set(item.foodId, item.foodId)
      );
      console.log(this.foodListData);
      this.$message.success("添加成功");
    },
    foodChange(val) {
      this.foodChangeData = val.map((item) => {
        return {
          foodName: item.title,
          foodId: item.foodId,
        };
      });
    },
    onAddFood() {
      // this.$refs.
      if (this.foodChangeData.length == 0) {
        return this.$message.error("请选择要添加的数据");
      }
      try {
        let map = new Map();
        this.foodListData = [
          ...this.foodListData,
          ...this.foodChangeData,
        ].filter(
          (item) => !map.has(item.foodId) && map.set(item.foodId, item.foodId)
        );
        this.$message.success("添加成功");
      } catch (err) { }
    },
    onSelectChange2(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.travelId);
      });
      this.travelListIds = arr;
    },
    onSelectChange3(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.seckillId);
      });
      this.seckListIds = arr;
    },
    onSelectChange4(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.groupBuyId);
      });
      this.groupListIds = arr;
    },
    onSelectChange5(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.presaleId);
      });
      this.presellListIds = arr;
    },
    onAddTicketRow(id) {
      if (this.ticketIds.indexOf(id) !== -1) {
        return this.$message({
          showClose: true,
          message: "数据已添加",
          type: "warning",
        });
      }
      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
      this.ticketIds.push(id);
      this.getticketDetails();
      console.log(this.ticketIds);
    },
    onAddTicket() {
      if (this.ticketListIds == "") {
        return this.$message({
          showClose: true,
          message: "请选择要添加数据",
          type: "error",
        });
      }
      this.ticketIds.push(...this.ticketListIds);
      this.ticketIds = Array.from(new Set(this.ticketIds));
      this.getticketDetails();
      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
    },
    onAddSeck() {
      if (this.seckListIds == "") {
        return this.$message({
          showClose: true,
          message: "请选择要添加数据",
          type: "error",
        });
      }
      this.seckIds.push(...this.seckListIds);
      this.seckIds = Array.from(new Set(this.seckIds));
      this.getSeckDetails();

      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
    },
    onAddGroup() {
      if (this.groupListIds == "") {
        return this.$message({
          showClose: true,
          message: "请选择要添加数据",
          type: "error",
        });
      }
      this.groupIds.push(...this.groupListIds);
      this.groupIds = Array.from(new Set(this.groupIds));
      this.getGroupDetails();

      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
    },
    onAddPresell() {
      if (this.presellListIds == "") {
        return this.$message({
          showClose: true,
          message: "请选择要添加数据",
          type: "error",
        });
      }
      this.presellIds.push(...this.presellListIds);
      this.presellIds = Array.from(new Set(this.presellIds));
      this.getPresellDetails();

      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
    },
    onSelectChange1(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.ticketId);
      });
      this.ticketListIds = arr;
    },
    onSelectChange(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.roomId);
      });
      this.roomListIds = arr;
    },
    onAddRoomRow(id) {
      if (this.roomIds.indexOf(Number(id)) !== -1) {
        return this.$message({
          showClose: true,
          message: "数据已添加",
          type: "warning",
        });
      }
      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
      this.roomIds.push(id);
      this.getroomDetails();
    },
    onAddRoom() {
      if (this.roomListIds == "") {
        return this.$message({
          showClose: true,
          message: "请选择要添加数据",
          type: "error",
        });
      }
      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
      this.roomIds.push(...this.roomListIds);
      this.roomIds = Array.from(new Set(this.roomIds));
      this.getroomDetails();

      // this.roomDialogVisible = false;
    },
    onClear(value) {
      if (value === 2) {
        this.roomList.hotelId = null;
      } else if (value === 3) {
        this.ticketList.sceneryId = null;
      } else if (value === 4) {
        this.ticketList.status = null;
      } else if (value === 1) {
        this.roomList.status = null;
      } else if (value === 5) {
        this.travelList.status = null;
      } else if (value === 6) {
        this.seckList.status = null;
      } else if (value === 7) {
        this.groupList.status = null;
      } else if (value === 8) {
        this.presellList.status = null;
      }
    },
    handleSizeChange(num) {
      this.roomList.pageSize = num;
      this.getRoomList();
    },
    handleCurrentChange(num) {
      this.roomList.currentPage = num;
      this.getRoomList();
    },
    handleSizeChange1(num) {
      this.ticketList.pageSize = num;
      this.getTicketList();
    },
    handleCurrentChange1(num) {
      this.ticketList.currentPage = num;
      this.getTicketList();
    },
    handleSizeChange2(num) {
      this.travelList.pageSize = num;
      this.getLineList();
    },
    handleCurrentChange2(num) {
      this.travelList.currentPage = num;
      this.getLineList();
    },
    handleSizeChange3(num) {
      this.seckList.pageSize = num;
      this.getSeckList();
    },
    handleCurrentChange3(num) {
      this.seckList.currentPage = num;
      this.getSeckList();
    },
    handleSizeChange4(num) {
      this.groupList.pageSize = num;
      this.getGroupList();
    },
    handleCurrentChange4(num) {
      this.groupList.currentPage = num;
      this.getGroupList();
    },
    handleSizeChange5(num) {
      this.presellList.pageSize = num;
      this.getPresellList();
    },
    handleCurrentChange5(num) {
      this.presellList.currentPage = num;
      this.getPresellList();
    },
  },
};
</script>
<style lang="less" scoped>
.addCoupon {
  .top {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }

  // /deep/ .el-form {
  //   margin-top: 50px;
  //   margin-left: 20px;
  //   .lingqu {
  //     margin-left: 10px;
  //   }
  //   .col {
  //     display: inline-block;
  //     .el-form-item__content {
  //       .el-input {
  //         width: 250px !important;
  //       }
  //     }
  //   }
  //   .el-form-item {
  //     .el-form-item__label {
  //       width: 80px !important;
  //       width: 80px !important;

  //       padding: 0;
  //     }
  //     .el-form-item__content {
  //       .el-input {
  //         width: 600px;
  //       }
  //       .el-date-editor {
  //         width: 291px;
  //       }
  //     }
  //   }
  // }
  .btn {
    margin-top: 50px;
    margin-left: 20px;
  }

  /deep/ .addHotel {
    .el-dialog {
      .el-dialog__body {
        .el-pagination {
          margin-top: 20px;
          float: right;
        }

        .add {
          margin-top: 20px;
        }

        .el-table {
          margin-top: 50px;
        }

        .search {
          span {
            margin: 0 20px;
          }

          .el-input {
            width: 300px;
          }

          .el-select {
            width: 300px !important;
          }

          .el-button {
            margin-left: 20px;
          }
        }
      }
    }
  }
}
</style>
