<template>
    <div class="thirdColumnList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>访问详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>手机号码</span>
      <el-input
        placeholder="请输入手机号码"
        v-model.trim="list.phone"
        type="number"
        maxlength="11"
      ></el-input>
      <el-button type="primary" @click="onSearch">搜索</el-button>
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
      <el-button type="primary" @click="createForm()">生成报表</el-button>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
    >
      <el-table-column
        
        prop="activityTitle"
        label="活动名称"
      >
      </el-table-column>
      <el-table-column
        
        prop="areaName"
        label="区域名称"
      >
      </el-table-column>
      <el-table-column
       
        prop="userName"
        label="访问姓名"
      >
      </el-table-column>
      <el-table-column
        
        prop="phone"
        label="手机号码"
      >
      </el-table-column>
      <el-table-column
        
        prop="addTime"
        label="访问时间"
      >
      </el-table-column>
    </el-table>
     <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="this.list.currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="100"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { selectForBack ,exportExcel} from "../../../api/activePage";
    export default {
  name: "regionalManagement",
        props: {
    activityId: {
      type: [Number, String],
      required: true,
    },
    areaId:{
        type: [Number, String],
    }
  },
  data(){
    return{
      total:'',
      tableData: [],
      list:{
        currentPage: 1,
        pageSize: 10,
        activityId:this.activityId,
        areaId:this.areaId,
        phone:''
      },
    }
  },
  created() {
    this.getselectForBackList();
  },
  methods:{
    async getselectForBackList(){
        const { data } = await selectForBack(this.list);
        this.tableData = data.list;
        this.total=data.pagination.total
        console.log(data.pagination.total,8888);
    },
    onSearch() {
      this.getselectForBackList();
    //   this.list.areaName = "";
    },
    handleSizeChange(sixe) {
      this.list.pageSize = sixe;
      this.getselectForBackList();
    },
    handleCurrentChange(Current) {
      this.list.currentPage = Current;
      this.getselectForBackList();
    },
    showCode(row) {
      this.codeVisible = true
      this.imgUrl = ''
      this.areaId = row.areaId
    },
    // 生成报表
   async createForm(){
    const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      let date = +new Date();
      const {phone, activityId,areaId} = this.list;
      let params ={
        phone,
        activityId,
        areaId
      }
      const { data } = await exportExcel(params);
      let url = window.URL.createObjectURL(data); //转换文件流未url
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", `万旅网小程序V2.0访问详情_${date}.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      loading.close()
    }
  }
    }
</script>

<style lang="less" scoped>

.thirdColumnList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    .el-input {
      width: 500px;
      margin: 0 30px;
    }
  }
  /deep/.el-table {
    margin-top: 50px;
  }
  .btn {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>